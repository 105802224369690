import * as React from 'react';
import styled from 'styled-components';
import { Anchor } from '../Anchor/Anchor';

export const LogInHeaderButton = styled(Anchor)`
  display: block;
  font-size: 1.4rem;
  border-radius: 0.8rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.primary16}; 
  font-family: 'Quicksand-Medium', sans-serif;
  margin-left: 1.4rem;
  padding: 0.7rem 1.9rem;

  @media (${props => props.theme.deviceBreakpoints.tablet}) {
    padding: 0.7rem 1.6rem;
  }

  &:hover {
    text-decoration: none;
  }
`;
