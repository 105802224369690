import styled from 'styled-components';

// base64 ./wave.svg | pbcopy
const WaveSvg = 'PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNjgzLjkwOSIgaGVpZ2h0PSI5NzEuOTgyIgogICAgIHZpZXdCb3g9IjAgMCAxNjgzLjkwOSA5NzEuOTgyIj4KICAgIDxkZWZzPgogICAgICAgIDxzdHlsZT4uYXtmaWxsOnVybCgjd2F2ZVNoYXBlKTt9PC9zdHlsZT4KICAgICAgICA8bGluZWFyR3JhZGllbnQgaWQ9IndhdmVTaGFwZSIgeDE9IjAuMDI2IiB5MT0iMC44ODciIHgyPSIwLjg5NSIgeTI9IjAuMDg3IiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCI+CiAgICAgICAgICAgIDxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iI2Y1ZjhmZCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICAgICAgICAgIDxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI2UxZWNmZiIvPgogICAgICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgICA8L2RlZnM+CiAgICA8cGF0aCBjbGFzcz0iYSIKICAgICAgICAgIGQ9Ik0yMDE2LjYzNS0yNC4yNzVDMTg3OS40ODcsMzUuNDI3LDE3MjQsMjQyLjY2NywxNjc0LjY2NywzODIuNjY3cy0xMjAsMjIwLTM2OS4zMzMsMjgwLTUwMCw0Ni42NjctNTAwLDQ2LjY2N1Y5MDIuN2MwLDQsMTY3Ni4yMDUtMjUuNjc5LDE2ODEuNTM5LTI1LjY3OXMwLTQ5NC4zNTYsMC00OTQuMzU2VjE3NS44czIzLjctMTcxLjg0OS05MC44NjMtMjEzLjMzUzIxNTMuNzg0LTgzLjk3OCwyMDE2LjYzNS0yNC4yNzVaIgogICAgICAgICAgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTgwNS4zMzMgNjguOTEpIi8+Cjwvc3ZnPgo=';

export const WaveContainer = styled.div`
  background-image: url(data:image/svg+xml;base64,${WaveSvg});
  background-repeat: no-repeat;
  background-size: 400%;
  background-position: 57% -28%;
  overflow: hidden;
  height: 100%;
  padding-top: 2.5rem;

  @media (${props => props.theme.deviceBreakpoints.tablet}) {
    background-position: 50% 60%;
    background-size: 300%;
  }
  
  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    background-position-y: -9rem;
    background-position-x: calc(100% + 34rem);
    background-size: 132%;
  }
  
  @media (${props => props.theme.deviceBreakpoints.desktop}) {
    padding-top: 7.2rem;
    background-position-x: calc(100% + 26rem);
    background-position-y: 33%;
    background-size: 140%;
  }
`;
