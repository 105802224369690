import * as React from 'react';
import styled from 'styled-components';
import { Anchor } from '../Anchor/Anchor';
import { LogInHeaderButton } from '../Buttons/LogInHeaderButton';
import { Logo } from '../Logo/Logo';

type Props = {
  siteTitle?: string;
  loginUrl?: string;
  blogUrl?: string;
  homePageUrl?: string;
  className?: string;
};

const APP_LOGIN_LINK_LABEL = 'Zaloguj się';

const Container = styled.div`
  display: flex;
  line-height: 1;
  align-items: center;

  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    margin-right: 6rem;
  }
`;

const HeaderStyled = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;    

  margin-top: -1.25rem;
  margin-left: 2rem;
  margin-right: 2rem;
    
  @media (${props => props.theme.deviceBreakpoints.mobile}) {
    margin-left: 4rem;
    margin-top: 0;
  } 
 
  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    margin-left: 14rem;
  }
  
  @media (${props => props.theme.deviceBreakpoints.desktop}) {
    margin-left: 28rem;
    margin-right: 28rem;
  }
`;

const Header: React.FC<Props> = ({
                                   siteTitle,
                                   loginUrl,
                                   blogUrl,
                                   homePageUrl,
                                   className,
                                 }) => (
  <HeaderStyled className={className}>
    <Anchor
      href={homePageUrl}
      title={siteTitle}
    >
      <Logo/>
    </Anchor>
    <Container>
      <LogInHeaderButton
        href={loginUrl}
      >
        {APP_LOGIN_LINK_LABEL}
      </LogInHeaderButton>
    </Container>
  </HeaderStyled>
);

Header.defaultProps = {
  siteTitle: 'FAKTURUJ.TO | Program do fakturowania',
  loginUrl: 'https://app.fakturuj.to/',
  blogUrl: 'https://programista.fakturuj.to/blog/',
  homePageUrl: 'https://fakturuj.to/',
};

export default Header;
