import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../../../../components/src/components/Layout/ThemeProvider/ThemeProvider';

type Props = {
  className?: string;
};

type ImageSharpFixed = {
  aspectRatio: number;
  base64: string;
  height: number;
  originalName: string;
  src: string;
  srcSet: string;
  srcSetWebp: string;
  srcWebp: string;
  tracedSVG: string;
  width: number;
};

type DeviceType =
  | 'small'
  | 'mobile'
  | 'tablet'
  | 'laptop'
  | 'desktop';

type ImagesQueryResult = Record<DeviceType, { childImageSharp: { fixed: ImageSharpFixed } }>;

const ImageComponent: React.FC<Props> = ({ className }) => {
  const data = useStaticQuery<ImagesQueryResult>(graphql`
      query {
          small: file(relativePath: { eq: "isometric-3-dark-a@2x-cut.png" }) {
              childImageSharp {
                  fixed(width: 460){
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          mobile: file(relativePath: { eq: "isometric-3-dark-a@2x-cut.png" }) {
              childImageSharp {
                  fixed(width: 576){
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          tablet: file(relativePath: { eq: "isometric-3-dark-a@2x-cut.png" }) {
              childImageSharp {
                  fixed(width: 768){
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          laptop: file(relativePath: { eq: "isometric-3-dark-a@2x-cut.png" }) {
              childImageSharp {
                  fixed(width: 992){
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          desktop: file(relativePath: { eq: "isometric-3-dark-a@2x-cut.png" }) {
              childImageSharp {
                  fixed(width: 1200){
                      ...GatsbyImageSharpFixed
                  }
              }
          }
      }
  `);

  const sources = [
    {
      ...data.laptop.childImageSharp.fixed,
      media: theme.deviceBreakpoints.laptop,
    },
    {
      ...data.tablet.childImageSharp.fixed,
      media: theme.deviceBreakpoints.tablet,
    },
    {
      ...data.mobile.childImageSharp.fixed,
      media: theme.deviceBreakpoints.mobile,
    },
    {
      ...data.small.childImageSharp.fixed,
      media: theme.deviceBreakpoints.small,
    },
  ];

  return (
    <div className={className}>
      <Img
        fixed={sources}
        fadeIn={false}
        loading="lazy"
      />
    </div>
  );
};

export const Image = styled(ImageComponent)`
  & img {
    object-fit: scale-down !important;
    max-width: 50%;
    max-height: 50%;
  }
  
  @media (${props => props.theme.deviceBreakpoints.mobile}) {
    display: flex;
    justify-content: center;
    margin-top: 5rem;

    & img {
      max-width: none;
    }
  }

  @media (${props => props.theme.deviceBreakpoints.tablet}) {
    & img {
      max-height: none;     
    }
  }


  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    margin-left: 10.2rem;
    margin-top: 2.1rem;
  }
`;
